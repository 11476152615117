import React, { useState } from "react";
import Card from "../_common/Card";
import TopPagination from "../_common/TopPagination";
import Pagination from "../_common/pagination/Pagination";
import { PrettyCurrency, PrettyLocalDateTime } from "../_common/all";

import { useGetClientAccountQuery } from "./_api";

const SOURCE_TYPES = {
  translation_request: 'Translation Request',
  translation_copilot: 'Translation Copilot',
  postediting_ai:      'Postediting AI',
  universal_chat:      'Universal Chat',
  content_generation:  'Content creation',
}

export default function ChatGPTHistory({ clientAccountId }) {
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(25);

  const { data, isLoading } = useGetClientAccountQuery({ id: clientAccountId, type: 'chatgpt', page, perPage });

  const machineTranslatorPromptHistories = data?.machineTranslatorPromptHistories;
  const machineTranslatorPromptHistoriesTotal = data?.machineTranslatorPromptHistoriesTotal;
  const machineTranslatorPromptHistoriesAiProofreaderStats = data?.machineTranslatorPromptHistoriesAiProofreaderStats;

  if (isLoading || !machineTranslatorPromptHistories) return 'Loading...';

  const { nodes, nodesCount, pagesCount } = machineTranslatorPromptHistories;

  const changePage = (page) => setPage(page);
  const changePageAndSize = (page, size) => {
    setPage(page);
    setPerPage(size);
  }

  const approvedByAiPercentage = () => {
    const totalSegmentsApprovedByAi = machineTranslatorPromptHistoriesAiProofreaderStats.totalSegmentsApprovedByAi;
    const totalSegments = machineTranslatorPromptHistoriesAiProofreaderStats.totalSegments;

    return ((totalSegmentsApprovedByAi / totalSegments) * 100).toFixed(2);
  }

  return (
    <>
      <h2>ChatGPT Histories</h2>
      <Card className="mb-4">
        <h5>Month total: <PrettyCurrency amount={machineTranslatorPromptHistoriesTotal} currency={'USD'} /></h5>
        { machineTranslatorPromptHistoriesAiProofreaderStats.totalSegmentsApprovedByAi > 0 && <div>
            Segment metrics:
            <p>
              Of total { machineTranslatorPromptHistoriesAiProofreaderStats.totalSegments } translated segments&nbsp;
              { machineTranslatorPromptHistoriesAiProofreaderStats.totalSegmentsApprovedByAi } were
              automatically approved by the AI ({ approvedByAiPercentage() }%).
            </p>
        </div> }
      </Card>

      <Card className="mb-4">
        <TopPagination {...{ changePageAndSize, totalPages: pagesCount, page, size: perPage }} />
        <table className="table mb-0">
          <thead>
            <tr>
              <th>
                Translation requests
              </th>
              <th>
                Amount
              </th>
              <th>
                Source
              </th>
              <th>
                Date
              </th>
            </tr>
          </thead>
          <tbody>
            { nodes.map((node) => <tr key={`history-${node.slug}`}>
              <td>
                <table>
                  <tbody>
                    { node.translationRequests.map((request) => <tr key={`history-tr-${request.id}`}>
                      <a href={`/translation-requests/${request.id}`}>{ request.title }</a>
                    </tr>)}
                  </tbody>
                </table>
              </td>
              <td><PrettyCurrency amount={node.amount} currency={'USD'} /></td>
              <td>
                {SOURCE_TYPES[node.source] }
              </td>
              <td><PrettyLocalDateTime date={node.createdAt}/></td>
            </tr>)}
          </tbody>
        </table>
        <Pagination className="justify-content-end" {...{ changePage, totalPages: pagesCount, page }} />
      </Card>
    </>
  );
}
